<template>
  <ag-card-container centered :title="$t('title.login')">
    <template #input>
      <ag-language-field
        :value="language"
        hide-details
        single-line
        style="width: 175px;"
        @input="setLanguage"
      />
    </template>
    <v-row>
      <v-col cols="8" offset="2">
        <login-form
          :loading="loading"
          @input="sendLoginRequest"
        />
      </v-col>
    </v-row>
  </ag-card-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AgCardContainer from 'src/components/common/AgCardContainer'
import AgLanguageField from 'src/components/common/AgLanguageField'
import LoginForm from '../components/LoginForm'

export default {
  name: 'LoginContainer',
  components: {
    AgCardContainer,
    LoginForm,
    AgLanguageField,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters('me', {
      language: 'language',
    }),
  },
  methods: {
    ...mapActions('me', {
      login: 'login',
      setLanguage: 'setLanguage',
    }),
    async sendLoginRequest (credentials) {
      this.loading = true
      await this.login(credentials)
      this.loading = false
    },
  },
}
</script>

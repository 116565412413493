<template>
  <v-container fuild class="fill-height">
    <v-row :class="{ 'fill-height': !centered }">
      <v-col
        :cols="fluid ? 12 : null"
        :sm="fluid ? null : 10"
        :md="fluid ? null : 8"
        :xl="fluid ? null : 6"
        :offset-sm="fluid ? null : 1"
        :offset-md="fluid ? null : 2"
        :offset-xl="fluid ? null : 3"
      >
        <v-card>
          <v-toolbar
            v-if="title"
            color="primary"
            dark
            flat
            dense
          >
            <v-row>
              <div class="col">
                <div class="text-center">
                  <v-toolbar-title v-text="title" />
                </div>
                <div
                  class="d-inline-block"
                  style="position: absolute; right: 10px; top: 10px"
                >
                  <slot name="input" />
                  <v-text-field
                    v-if="hasSearch"
                    :value="search"
                    :placeholder="$t('general.search')"
                    prepend-icon="mdi-magnify"
                    hide-details
                    single-line
                    @input="$emit('update:search', $event)"
                  />
                </div>
              </div>
            </v-row>

            <template v-if="$slots.extension" #extension>
              <slot name="extension" />
            </template>
          </v-toolbar>
          <v-card-text>
            <slot />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AgCardContainer',
  props: {
    title: {
      type: String,
      default: '',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
  },
  computed: {
    hasSearch () {
      return this.search !== null
    },
  },
}
</script>
